.menu-card {
    text-align: center;
    padding: 10px 10px;
    margin: 5px;
    border-radius: 15px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;

    .icon-holder {
        background: var(--ion-color-primary-tint);
        height: 80px;
        width: 80px;
        border-radius: 50%;
        font-size: 30px;
        display: flex;
        margin: 15px auto 20px;
        align-items: center;
        justify-content: center;
    }
}
