.lower-content {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

form {
    width: 100%;

    .form-item {
        margin-bottom: 10px;
        position: relative;

        &:last-of-type {
            margin: 0;
        }

        .password-show-icon {
            position: absolute;
            right: 0;
            bottom: 5px;
            --background: var(--ion-color-secondary);
            z-index: 999;
        }
    }

    ion-item {
        --padding-start: 0;
        --inner-padding-start: 0;
    }

    p,
    ion-item,
    ion-input {
        color: var(--ion-color-primary) !important;

        input {
            color: var(--ion-color-primary) !important;
        }
    }

    .meter-reading-card {
        margin: 0;
        padding: 0 0 25px;
    }
}

.blue-form,
.login-form,
.registration-form {
    width: 100%;
    padding: 20px;
    margin-top: 30px;
    border-radius: 30px;
    background: url("../assets/images/background.png") 0 0/100% 100% no-repeat;

    p,
    ion-item,
    ion-input,
    ion-icon {
        color: #fff !important;
        --background: transparent;

        input {
            color: #fff !important;
        }
    }

    ion-item {
        --padding-start: 0;
        --inner-padding-start: 0;
    }

    ion-router-link {
        font-size: 16px;
        line-height: 20px;
        text-decoration: underline;
        color: var(--ion-color-light);
    }
}

@media (min-width: 1025px) {
    .authentication-page-grid {
        max-width: 420px;
    }
}