.warning-modal-content {
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;

    .lower-content {
        margin-top: 30px;
        flex-grow: 1;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
}
