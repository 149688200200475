.news ion-card {
    padding: 0;
    border-radius: 20px;
    margin: 0;
    color: white;

    .news-card {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: flex-end;
        padding: 16px 20px;
        min-height: 200px;
        position: relative;
        background-size: cover !important;

        &:not(.skeleton) {
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: rgb(0,0,0);
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.35) 60%, rgba(0,0,0,1) 100%);
                z-index: 1;
            }
        }
    
        .card-title {
            font-size: 18px;
            line-height: 21px;
            font-weight: bold;
            z-index: 5;
        }

        .card-date {
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            z-index: 5;
            margin-bottom: 4px;
        }
    }
}
