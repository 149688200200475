.news-data-page {
    .news-data-header {
        h1 {
            color: var(--ion-color-primary);
            font-weight: bold;
            margin-bottom: 5px;
            line-height: 32px;
        }
    
        h5 {
            font-size: 13px;
            font-weight: 400;
            margin-bottom: 15px;
            color: var(--ion-color-dark);
        }

        img {
            width: 100%;
            object-fit: cover;
            max-height: 200px;
        }
    }

    .news-data-content {
        h1 {
            font-size: 20px !important;
            line-height: 26px;
        }
        
        strong,
        span,
        p {
            font-size: 16px !important;
            line-height: 22px;
            color: var(--ion-color-dark-shade);
            margin: 2px 0;
        }

        img {
            margin: 0 !important;
            width: 100%;
            object-fit: cover;
            max-height: 400px;
        }
    }
}
