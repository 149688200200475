:root {
  --ion-color-primary: #00509D;
  --ion-color-primary-rgb: 0,80,157;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #00468a;
  --ion-color-primary-tint: #1a62a7;

  --ion-color-secondary: #0097D3;
  --ion-color-secondary-rgb: 0,151,211;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #0085ba;
  --ion-color-secondary-tint: #1aa1d7;

  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82,96,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #848484;
  --ion-color-dark-rgb: 132,132,132;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0,0,0;
  --ion-color-dark-shade: #747474;
  --ion-color-dark-tint: #909090;

  --ion-color-medium: #e5e5e5;
  --ion-color-medium-rgb: 229,229,229;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #cacaca;
  --ion-color-medium-tint: #e8e8e8;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

.ion-color-lightblue {  
  --ion-color-base: var(--ion-color-secondary);
  --ion-color-base-rgb: var(--ion-color-secondary-rgb);
  --ion-color-contrast: var(--ion-color-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary-shade);
  --ion-color-tint: var(--ion-color-secondary-tint);
  color: var(--ion-color-secondary);
}

.ion-color-middleblue {  
  --ion-color-base: var(--ion-color-primary);
  --ion-color-base-rgb: var(--ion-color-primary-rgb);
  --ion-color-contrast: var(--ion-color-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary-tint);
  color: var(--ion-color-primary);
}

.ion-color-dark {  
  --ion-color-base: var(--ion-color-dark);
  --ion-color-base-rgb: var(--ion-color-dark-rgb);
  --ion-color-contrast: var(--ion-color-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-shade);
  --ion-color-tint: var(--ion-color-dark-tint);
  color: var(--ion-color-dark);
}
