@font-face {
    font-family: "Inter";
    src: url("../assets/fonts/Inter-VariableFont_slnt,wght.ttf");
    font-weight: 1 999;
}

* {
    font-family: "Inter";
}

input {
    background-color: transparent !important;
    color: #fff !important;
}

body {
    background: #fff;
}

.grid-fixed {
    position: relative;
}

.pull-right {
    text-align: right;
}

.page-title {
    margin: 0 0 20px 0px;
    color: var(--ion-color-primary);
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
}

h3.meter-card-title {
    margin: 0 0 10px 0px;
    color: var(--ion-color-primary);
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
}

/*ion-label {
    color: var(--ion-color-light) !important;
}*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    margin: 0 !important;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield !important;
}

ion-header {
    width: 100%;
    text-align: center;
    z-index: 999;
    padding: 10px 0;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);

    img {
        height: 45px;
    }
}

/* FOOTER */

ion-tab-bar {
    background-color: var(--ion-color-primary);
    padding: 0;
    height: 84px;
    display: flex;
    align-items: center;

    ion-tab-button {
        background: transparent;
        color: #fff;

        .icon-holder {
            background: var(--ion-color-primary-tint);
            border-radius: 16px;
            width: 44px;
            height: 44px;
            font-size: 22px;
            display: flex;
            justify-content: center;
            align-self: center;

            ion-icon {
                height: auto;
            }
        }

        &.tab-selected {
            position: relative;

            .icon-holder {
                background: var(--ion-color-secondary-tint);
            }

            &::after {
                content: "";
                background: var(--ion-color-secondary-tint);
                position: absolute;
                bottom: 0;
                left: 20%;
                width: 60%;
                height: 4px;
            }
        }
    }

    ion-tab-button:hover {
        ion-label {
            color: var(--ion-color-light);
        }
    }

    .tab-menu-text {
        font-size: large;
    }
}

.large-btn {
    border-radius: 35px;
    height: 72px;
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    --background: var(--ion-color-secondary-tint);
    box-shadow: 0 12px 24px #0097d387;

    ion-icon {
        color: #fff;
    }

    &.medium-btn {
        --background: var(--ion-color-primary);
        box-shadow: none;

        ion-icon {
            color: #fff;
        }
    }
}

.hidden {
    display: none !important;
}

.textItem {
    p {
        margin: 0 !important;
    }

    ion-label {
        max-height: 20px;
        color: var(--ion-color-primary) !important;
    }
}

.textarea-with-counter .native-textarea, ion-textarea {
    min-height: 40px;
}
.textarea-counter {
    text-align: right;
}

.news-data-content iframe {
    height: auto;
    width: 100%;
    border: 0;
}
.news-data-content a {
    color: black;
}
.contacts-page a {
    color: black;
}

.authentication-page-grid {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #00509d inset !important;
        -webkit-text-fill-color: white !important;
    }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-text-fill-color: var(--ion-color-primary) !important;
}

.appDownloadBox {
    display: none !important;
}

ion-title {
    padding: 0;
}

.pvizNewsLogo {
    width: 100%;
    margin: 0 0 25px;
}

ion-card {
    margin: 0;

    .news-card {
        color: #fff;

        .card-date {
            opacity: 0.7;
            text-align: left;
        }

        .card-title {
            text-align: left;
        }
    }
}

/*.newsSlide {
    display: flex;
    flex-flow: column;

    .card-desc {
        color: #000;
        opacity: 1;
    
        span,
        p {
            text-align: left;
            font-size: 15px !important;
            color: #000 !important;
            opacity: 1;
        }
    }
}*/

.swiper-container {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100vh - 148px);
    width: 100vw;
    z-index: 50;

    .newsSlide {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;

        .newsSlideImg {
            width: 100vw;
            height: 250px;
            background-size: cover;
        }

        .newsSlideBottom {
            position: absolute;
            top: 230px;
            left: 0;
            width: 100vw;
            background: #fff;
            padding: 20px;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            max-height: calc(100vh - 329px);
            overflow: auto;
            text-align: left;

            .newsSlideButton {
                display: flex;
                justify-content: space-between;
            }

            .newsSlideDate {
                font-size: 11px;
                text-align: left;
                opacity: 0.6;
                margin-bottom: 10px;
                color: #000;
            }

            .newsSlideTitle {
                font-size: 22px;
                text-align: left;
                color: var(--ion-color-primary);
                font-weight: 600;
            }

            .newsSlideDesc {
                margin: 10px 0;
                text-align: left;
                font-size: 15px !important;
                color: #000 !important;
                opacity: 1;

                span,
                p {
                    text-align: left;
                    font-size: 15px !important;
                    color: #000 !important;
                    opacity: 1;
                }
            }

            .newsSlideLink {
                text-align: left;
                font-size: 15px !important;
                color: var(--ion-color-primary);
                font-weight: 600;
            }
        }
    }
}

.spinnerCenter {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 991px) {
    .tab-menu-text {
        display: none;
    }
}

@media screen and (min-width: 992px) {
    .pvizNewsLogo {
        display: none;
    }

    ion-tab-bar {
        position: absolute;
        top: 60px;
        z-index: 99999;
        width: 100%;
        height: 50px;
        border: 0;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -2px rgba(0, 0, 0, 0.05);

        ion-tab-button {
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: center;
            max-width: 235px;

            .icon-holder {
                background: transparent !important;

                ion-icon {
                    font-size: 20px;
                }
            }

            ion-label {
                font-size: 15px !important;
                font-weight: 400;
            }

            &:hover,
            &.tab-selected {
                position: relative;
                background: var(--ion-color-secondary-tint);

                .icon-holder {
                    color: #fff !important;
                }

                &::after {
                    display: none;
                }
            }
        }
    }

    .tabs-inner ion-grid {
        padding-top: 60px;
    }

    .authentication-page-grid
        .passwordrecovery-page-grid
        .lostpassword-page-grid {
        max-width: 40vw;
    }

    ion-header {
        box-shadow: none;
        max-width: var(--ion-grid-width-xl, var(--ion-grid-width, 1140px));
        margin: 0 auto;
        padding: 0;
        height: 60px;

        .buttons-first-slot {
            display: none !important;
        }

        ion-toolbar {
            height: 60px;
            --min-height: 60px;
        }

        ion-title {
            text-align: left;
            padding: 0;
            height: 60px;

            img {
                height: 55px;
            }
        }
    }

    .news-data-content {
        iframe {
            min-height: 550px;
        }

        img {
            width: auto !important;
        }
    }

    .news {
        .appDownloadBox {
            position: relative;
            display: flex !important;
            flex-flow: column;
            justify-content: center;
            align-items: flex-start;
            height: 200px;
            width: 100%;
            padding: 35px;
            margin: 20px 0 50px 0;
            border-radius: 30px;
            background: url("../assets/images/background.png") 0 0/100% 100%
                no-repeat;

            .appImg {
                position: absolute;
                bottom: -35px;
                right: 45px;
                height: 270px;
            }

            h4 {
                color: #fff;
                margin: 0 0 20px;
                max-width: 500px;
                line-height: 28px;
                font-size: 20px;
                font-weight: 300;
            }

            .downloadButtons {
                display: flex;
                width: 450px;
                justify-content: space-between;
                height: 65px;

                a {
                    display: block;

                    img {
                        max-height: 65px;
                    }
                }
            }
        }

        ion-card {
            margin: 0;

            .news-card {
                min-height: 325px;
                padding: 25px;

                .card-date {
                    opacity: 0.7;
                }

                .card-title {
                    font-size: 24px;
                    line-height: 30px;
                }
            }
        }
    }

    .menu-card {
        padding: 65px 15px;
        font-size: 18px;
        font-weight: 500;

        .icon-holder {
            margin: 0 auto 25px;
        }
    }

    .swiper-container {
        position: relative;
        width: 100%;
        height: 550px;

        .newsSlide {
            position: relative;

            .newsSlideImg {
                width: 100%;
                height: 425px;
                background-size: cover;
                border-radius: 20px;
            }

            .newsSlideBottom {
                position: absolute;
                top: auto;
                bottom: 40px;
                left: 20px;
                width: calc(100% - 40px);
                background: #fff;
                padding: 20px;
                border-radius: 20px;
                max-height: auto;
                text-align: left;
                box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
                    0 4px 6px -2px rgba(0, 0, 0, 0.05);

                .newsSlideButton {
                    display: flex;
                    justify-content: space-between;
                }

                .newsSlideDate {
                    font-size: 11px;
                    text-align: left;
                    opacity: 0.6;
                    margin-bottom: 10px;
                    color: #000;
                }

                .newsSlideTitle {
                    font-size: 22px;
                    text-align: left;
                    color: var(--ion-color-primary);
                    font-weight: 600;
                }

                .newsSlideDesc {
                    margin: 10px 0;
                    text-align: left;
                    font-size: 15px !important;
                    color: #000 !important;
                    opacity: 1;

                    span,
                    p {
                        text-align: left;
                        font-size: 15px !important;
                        color: #000 !important;
                        opacity: 1;
                    }
                }

                .newsSlideLink {
                    text-align: left;
                    font-size: 15px !important;
                    color: var(--ion-color-primary);
                    font-weight: 600;
                }
            }
        }
    }
}
